<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
    <g id="Сгруппировать_12" data-name="Сгруппировать 12" transform="translate(-1.25 -1.25)">
      <path
        id="Контур_101"
        data-name="Контур 101"
        d="M12,22a10,10,0,1,0-7.071-2.929A9.97,9.97,0,0,0,12,22Z"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_102"
        data-name="Контур 102"
        d="M12,5.5a1.25,1.25,0,1,1-1.25,1.25A1.25,1.25,0,0,1,12,5.5Z"
        fill="#717171"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_103"
        data-name="Контур 103"
        d="M12.25,17V10h-1"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
      <path
        id="Контур_104"
        data-name="Контур 104"
        d="M10.5,17H14"
        fill="none"
        stroke="#717171"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :class="active ? 'svgColor' : ''"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  setup() {}
}
</script>

<style scoped>
.svgColor {
  stroke: #2473da;
}
</style>
