<script setup>
// import { useUnleashStore } from '~/stores/unleash.store'
const route = useRoute()
const routePath = computed(() => '/' + route.path.split('/')[1])
const authToken = useCookie(COOKIE_KEYS.authToken)
// const unleashStore = useUnleashStore()
</script>

<template>
  <div class="relative z-100">
    <div class="fixed left-0 bottom-0 w-full bg-white" style="box-shadow: 0px 0px 10px #12182214">
      <div class="container px-4 xl:px-0 py-3.5 items-center h-18 grid mx-auto flex flex-row w-full justify-between">
        <nuxt-link to="/" class="flex flex-col items-center space-y-2 min-w-max px-8"
          style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
          <a-icon-star class="w-6 h-6 cursor-pointer" :active="routePath === '/'" />
          <p class="font-sf-pro text-xs font-normal" :style="routePath === '/' ? 'color:#2169D5' : 'color:#717171'">
            Главная
          </p>
        </nuxt-link>
        <nuxt-link to="/filter-events" class="flex flex-col items-center flex-col space-y-2  min-w-max px-8"
          style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
          <a-icon-filter class="w-6 h-6 cursor-pointer" :active="routePath === '/filter-events'" />
          <p class="font-sf-pro text-xs font-normal"
            :style="routePath === '/filter-events' ? 'color:#2169D5' : 'color:#717171'">
            Фильтр
          </p>
        </nuxt-link>
        <nuxt-link to="/info" class="flex flex-col items-center flex-col space-y-2  min-w-max px-8"
          style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
          <a-icon-tooltip class="w-6 h-6 cursor-pointer" :active="routePath === '/info'" />
          <p class="font-sf-pro text-xs font-normal"
            :style="routePath === '/info' ? 'color:#2169D5' : 'color:#717171'">
            Информация
          </p>
        </nuxt-link>
        <!-- <nuxt-link style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" v-if="unleashStore.isAuthorizationEnable"
          :to="authToken ? '/user' : '/login'" class="flex flex-col items-center space-y-2  min-w-max px-8">
          <a-icon-profile class="w-6 h-6 cursor-pointer" :active="routePath === '/user' || routePath === '/login'" />
          <p class="font-sf-pro text-xs font-normal" :style="routePath === '/user' || routePath === '/login' ? 'color:#2169D5' : 'color:#717171'
            ">
            Профиль
          </p>
        </nuxt-link> -->
      </div>
    </div>
  </div>
</template>
